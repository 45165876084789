import React from 'react';

import styled from 'styled-components';

const HeaderCont = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

export const Header = () => {
  return <HeaderCont>Header goes here.</HeaderCont>;
};
