import React from 'react';

import styled from 'styled-components';

const FooterCont = styled.div`
  background-color: #000000;
`;

const TopFooter = styled.div`
`;

const FooterBottom = styled.div`
`;

const InnerFooter = styled.div`
  display: "flex";
  justify-content: "space-between";
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  color: #fff;
`;

export const Footer = () => {
  return (
      <FooterCont>
        <TopFooter>
          <InnerFooter>
            Top Footer
          </InnerFooter>
        </TopFooter>
        <FooterBottom>
          <InnerFooter>
            Bottom Footer
          </InnerFooter>
        </FooterBottom>
      </FooterCont>
  );
};
