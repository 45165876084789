import React from 'react';

import { DefaultLayout } from './layouts/default';

import { HomePage } from './pages/home/home';

export const App = () => {
  return (
    <DefaultLayout>
      <HomePage />
    </DefaultLayout>
  );
};

export default App;
