import React from 'react';

import styled from 'styled-components';

import { Header } from '../components/header/header';
import { Footer } from '../components/footer/footer';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const PageContent = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  flex-grow: 1;
`;

export const DefaultLayout = (props) => {
  return (
    <>
      <Header />
      <PageContainer>
        <PageContent>{props.children}</PageContent>
      </PageContainer>
      <Footer />
    </>
  );
};
